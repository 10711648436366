<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Upload from "@/components/widgets/file-order";

export default {
  locales: {
    pt: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pagamento',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'Aguardando pagamento',
      'Payment confirmed': 'Pagamento confirmado',

      'Address': 'Endereço',
      'Send exactly value to make payment': 'Envie exatamente o valor para efetuar o pagamento',

      'The order was not found.': 'O pedido não foi encontrado.',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
    },
    es: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pago',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'En espera de pago',
      'Payment confirmed': 'Pago confirmado',

      'Address': 'Dirección',
      'Send exactly value to make payment': 'Enviar exactamente el valor de hacer el pago',

      'The order was not found.': 'O pedido não foi encontrado.',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
    }
  },
  components: {
    Layout,
    Upload
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        date: null,

        subtotal: '0.00',
        shipping: {
          method: '',
          value: '0.00',
          address: {
            zipcode: '',
            address: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            state: '',
          }
        },
        payment: {
          methods: [],
          list: [],
        },

        total: '0.00',
        topay: '0.00',

        items: null,
        status: null,
      },

      document: {
        loading: false,
        status: false,
        id: null,
        title: null,
        file: null,
      },

      file: null,

      proof: {
        add: false,
        loading: false,
        errored: false,
        status: false,
        list: null,
      },
    };
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get('store/orders/'+this.order.id)
          .then(response => {
            if (response.data.status=='success') {
              this.order = response.data.order

              this.order.topay = parseFloat(response.data.order.total)
              response.data.order.payment.list.forEach(e => {
                if (e.value > 0) {
                  this.order.topay -= parseFloat(e.value)
                }
              });

              this.loading = false
            } else {
              this.empty = true
              this.message = response.data.message
            }
          })
          .catch(error => {
            this.errored = error
          })
      }
    },
    getFiles: function () {
      this.proof.loading = true

      api
      .get('store/orders/file/'+ this.order.id)
      .then((response) => {
        if (response.data.status == 'success') {
          this.proof.list = response.data.files
        } else {
            this.proof.list = null
          }
        })
        .catch(error => {
          this.proof.errored = error
          this.proof.loading = false
        })
        .finally(() => {
          this.proof.loading = false
        })
    },
  },
  mounted() {
    this.getOrder()
    this.getFiles()
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/wallet/deposits">Meu Depósitos</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Consultor Depósito</li>
    </ol>

    <div v-if="loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="default" role="status"></b-spinner>
    </div>
    <template v-else>
      <div class="page-title-box d-md-flex justify-content-between mb-3">
        <div>
          <h3>Consultar Depósito</h3>
          <p></p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="card">
            <div class="card-body p-4">
              <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
              <div v-else-if="empty">{{ t('Nenhum registro encontrado.') }}</div>
              <div v-else class="p-2">
                <div class="d-flex">
                  <h4 class="mb-0 font-size-15 flex-fill">
                    #{{ order.id }}
                  </h4>
                  <h4 class="mb-0 font-size-15 flex-fill text-right">
                    {{ order.date }}
                  </h4>
                </div>
                <hr />
                <div class="table-responsive pb-5">
                  <table class="table table-nowrap">
                    <thead>
                      <tr>
                        <th class="border-0 text-uppercase text-center">{{ t('Item') }}</th>
                        <th class="border-0 text-uppercase text-center">{{ t('Total') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(td,index) in order.items" :key="index">
                        <td class="text-center">{{ td.name }} </td>
                        <td class="text-center">{{ td.subtotal | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3 class="font-size-16 mb-2">{{ t('Status') }}</h3>
                <div v-for="(td,index) in order.status" :key="index">
                  {{ td.date }} — {{ t(td.status) }}
                </div>
                <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-5" content-class="p-3 text-muted">
                  <b-tab v-for="(pay,method) in order.payment.methods" :key="method">
                    <template v-slot:title>{{ pay.name }}</template>
                    <div v-if="method == 'PIX'" class="text-center">
                      <img style="width: 250px;" :src="pay.qrcode" />
                      <p>Leia o qrcode acima ou</p>
                      <ol class="text-left">
                        <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                        <li>Busque a opção de pagar com pix.</li>
                        <li>Copie e cole o seguinte código.</li>
                        <li>Após o pagamento envie o comprovante da tranferência.</li>
                      </ol>

                      <div class="border rounded p-3 mb-3 text-left">
                        {{ pay.copypaste }}
                      </div>

                      <button class="btn btn-default" v-clipboard:copy="pay.copypaste"><i class="bx bx-copy font-size-18 align-middle"></i> Copiar</button>

                      <hr class="">
                      <div class="mt-5 text-center">
                        <h3 class="font-size-16 mb-2">Comprovantes</h3>
                        <p>Após realizar seu pagamento, anexe seu comprovante aqui.</p>
                        <div class="" v-for="(prof, index) in proof.list" :key="index">
                          <a class="btn btn-default" target="_blank" :href="prof.url">
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
                              <path d="m12 16 4-5h-3V4h-2v7H8z"></path>
                              <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>
                            </svg>
                            Ver Comprovante
                          </a>
                        </div>
                        <div v-if="proof.list.length == 0" class="text-center p-0">
                          <Upload :id="order.id" />
                        </div>
                      </div>
                    </div>
                    <div v-else-if="method == 'mercadopago'" class="text-center">
                      <img class="mt-1 mb-4" height="50px" src="@/assets/images/payments/logo-mercadopago.svg"><br>
                      <a target="_blank" class="btn btn-default btn-lg" :href="pay.url">Clique aqui para pagar</a>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>